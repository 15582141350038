
import React, {Fragment, Suspense, useState, useCallback, useEffect, useRef, useMemo ,useLayoutEffect} from 'react'
import { Canvas, useFrame, useThree ,useLoader} from '@react-three/fiber'
import * as THREE from 'three'
import { useIntersect, Image, ScrollControls, Scroll ,Loader,Preload,useScroll} from '@react-three/drei'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import './Home.css';
import { Swiper, SwiperSlide ,Scrollbar} from 'swiper/react';

//import Axios from "../components/Axios";
import Axios from 'axios';

//import { ScrollControls, Scroll, useScroll } from './ScrollControls'
var intstart=0
var tempactCount=0
var tempPosA=[]
var tempScale=[]
const totalconst = 44
var picname = []
var column=0
var canloop=true
var actcount=0
var dir='down'
var scrolldata=0
var ontap=false
var intervaltimer=1600
var showbtn=false
var toum=false
var intro=true
var introarrow=false

/*
function Box(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()
    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    useFrame((state, delta) => (mesh.current.rotation.x += 0.01))
    // Return view, these are regular three.js elements expressed in JSX
    return (
        <mesh
            {...props}
            ref={mesh}
            scale={active ? 1.5 : 1}
            onClick={(event) => setActive(!active)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
        </mesh>
    )
}

*/

function Item({ url, scale, ...props }) {
    const visible = useRef(false)
    const [hovered, hover] = useState(false)
    const group = useRef()
    const ref = useIntersect((isVisible) => (visible.current = isVisible))
    const { height } = useThree((state) => state.viewport)
    const { size, viewport,gl, camera } = useThree()


    useFrame((state, delta) => {

      // group.current.position.z =-200

        if(column==0)return
       if(column==2)return



      //  ref.current.position.z =THREE.MathUtils.damp(ref.current.position.z, visible.current ? 1 : -300+10, 4, delta)
        //ref.current.position.y = ref.current.position.y
       // ref.current.rotation.x= THREE.MathUtils.damp(ref.current.rotation.x,props.torotation[0], 3, delta)
            var movey=props.toposition[1]-tempPosA[props.Aid][1]
            var movex=props.toposition[0]-tempPosA[props.Aid][0]
             var scalex=props.toscale[0]
             var scaley=props.toscale[1]


               // ref.current.position.y =THREE.MathUtils.damp(ref.current.position.y,movey, .6, 0.02)
               // ref.current.position.x =THREE.MathUtils.damp(ref.current.position.x,movex, .6, 0.02)
                // ref.current.scale.x=THREE.MathUtils.damp(ref.current.scale.x,scalex, .6, 0.02)
                //  ref.current.scale.y=THREE.MathUtils.damp(ref.current.scale.y,scaley, .6, 0.02)

               if(intro){
                   var tspeed=0.02
                   if(props.remark==0){
                       ref.current.scale.x =scalex
                       ref.current.scale.y =scaley
                   }else{

                       if(props.remark==11){

                           ref.current.scale.x=THREE.MathUtils.damp(ref.current.scale.x,scalex, 3, 0.02)
                           ref.current.scale.y=THREE.MathUtils.damp(ref.current.scale.y,scaley, 3, 0.02)
                       } else{
                           ref.current.scale.x+=tspeed
                           ref.current.scale.y+=tspeed
                       }
                   }
               }else{
                   ref.current.scale.x=THREE.MathUtils.damp(ref.current.scale.x,scalex, 2, 0.02)
                    ref.current.scale.y=THREE.MathUtils.damp(ref.current.scale.y,scaley, 2, 0.02)
               }



                  ref.current.position.z=props.toposition[2]

        ref.current.material.transparent=true
        ref.current.material.opacity=props.alpha

           // ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, props.grey[props.Aid], 4, delta)

       // ref.current.material.grayscale=props.grey[props.Aid]
        // ref.current.position.y =THREE.MathUtils.damp(ref.current.position.y,tempPosA[props.Aid]==props.toposition[1]?-2:0, 3, delta)
       // ref.current.material.zoom = THREE.MathUtils.damp(ref.current.material.zoom, visible.current ? 1 : 1.5, 4, delta)
       //
    })
    return (
        <group {...props} ref={group}>
            <Image ref={ref} onPointerOver={() => hover(true)} onPointerOut={() => hover(false)} scale={scale}   url={url} />
        </group>
    )
}




function Items(props) {
    const { width: w, height: h } = useThree((state) => state.viewport)

    const cardW = w
    const cardH = w
    const ViewH = h

    var scalelist = []
    const group = useRef()
    var startpointx = 0, startpointy = 0, startpointz = 0
    var posList = []
    var alphalist = []
    var greylist=[]
    var remarklist=[]

    var rotationlist=[]
    const [posState, setposstate] = useState(posList)
    const [scaleState, setscalestate] = useState(scalelist)
    const [alpha,setalpha]=useState(alphalist)
    const [grey,setgrey]=useState(greylist)
    const [rota,setrota]=useState(rotationlist)
    const [remark,setremark]=useState(remarklist)


    const data = useScroll()

    var ipn = 1

    for (let i = 0; i < totalconst; i++) {
        if (ipn > 44) ipn = 1
        var ids = 'images/' + ipn + ".jpg"
        picname.push(ids)
        ipn++
    }




    //Outer loop position
    for (let i = 0; i < totalconst; i++) {
        // var tempx=0,tempy=startpointy-scalelist[1]*i,tempz=-200
        posList.push([0, 0 , -100])
        scalelist.push([cardW * .001, cardH * .001, 1])
        alphalist.push(0)
        greylist.push(1)
        remarklist.push(0)
        rotationlist.push([0,0,0])
    }

    //main loop position
    var scPec = [3,2.5,2,1.6,1.3, 1.1, .72, .49, .38, .27, .18]

    for (let i = 0; i < 11; i++) {
        scalelist[i] = [cardW * scPec[i], cardH * scPec[i], 1]
        posList[i] = [0, 0 , -100]
        alphalist[i]=1
        greylist[i]=1
        remarklist[i]=i+1

    }



    if(intstart==0){




        tempPosA = posList.slice()
        tempScale = scalelist.slice()

        setposstate(posList)
        setscalestate(scalelist)
        setalpha(alphalist)
        setgrey(greylist)
        setremark(remarklist)
        intstart=1


    }


    function remaplooping(mapcount){


        var newposList=[]
        var newscalelist=[]
        var newalphalist=[]
        var newgreylist=[]
        var toz=-100
        var greyval=0
        var rotationlist=[]
        var newremark=[]


        // console.log(mapcount+'change')

        for (let i = 0; i < totalconst; i++) {
            // var tempx=0,tempy=startpointy-scalelist[1]*i,tempz=-200
            newposList.push([0 , 0, toz])
            newscalelist.push([cardW * .001, cardH * .001, 1])
            newalphalist.push(0)
            newgreylist.push(greyval)
            rotationlist.push([0,0,0])
            newremark.push(0)
        }
        //main loop position
        var scPec = [3,2.5,2,1.6,1.3, 1.1, .72, .49, .38, .27, .18]
        for (let i =0; i < 11; i++) {

            var Markindex=i+mapcount

            //loop index
            if(Markindex>=totalconst){
                Markindex=Markindex-totalconst
                toz=-99.9 //push up z position
                newposList[Markindex+1][2]=-99.9

            }
            //end of the list z-index change
            if(7+mapcount==totalconst-1){
                newposList[0][2]=-99.9
                newalphalist[0]=1

            }


            if(column==2){
                //toz=-100-i*-10
                //rotationlist[Markindex][0]=Math.PI/5*-i/2
                greyval=1
            }else{
                rotationlist[Markindex][0]=0
                //greyval=0
            }


            newscalelist[Markindex] = [cardW * scPec[i], cardH * scPec[i], 1]
            newposList[Markindex] = [0, 0, toz]
            newalphalist[Markindex]=1
            newremark[Markindex]=i+1

            newgreylist[Markindex]=greyval
            if(column==1){
                newalphalist[Markindex+1]=1
            }else{
                newalphalist[Markindex+1]=0
                newalphalist[0]=0
            }
        }



        setposstate(newposList)
        setscalestate(newscalelist)
        setalpha(newalphalist)
        setgrey(newgreylist)
        setrota(rotationlist)
        setremark(newremark)
        tempactCount=mapcount

        intstart+=1
    }




    useLayoutEffect(()=>{

        if(tempactCount!=props.act){

            remaplooping(props.act)

        }

        showbtn=true
    })



    useFrame((state, delta) => {

         if(intstart==1){
             console.log('start')
             column=1
         }
       // group.current.position.z=0
        if(column===2) {
            group.current.position.y=THREE.MathUtils.damp(group.current.position.y,10 , 4, delta)
            // group.current.position.z=THREE.MathUtils.damp(group.current.position.z,-660 , 4, delta)
           //  group.current.rotation.z =THREE.MathUtils.damp(group.current.rotation.z,Math.PI/4 , 4, delta)
           // group.current.rotation.y+=0.0001
        }else{
             // group.current.position.z=THREE.MathUtils.damp(group.current.position.z,0 , 4, delta)
              //group.current.rotation.z =THREE.MathUtils.damp(group.current.rotation.z,0 , 4, delta)
            group.current.position.y=THREE.MathUtils.damp(group.current.position.y,0 , 4, delta)
      // group.current.rotation.y=0
        }
    })




    return (
        <group ref={group}>

            {posList.map((props, index) => (
                <Item url={picname[index]} key={index} scale={scalelist[index]} alpha={alpha[index]}  toscale={scaleState[index]} Aid={index} position={posList[index]} toposition={posState[index]} grey={grey} torotation={rota[index]} remark={remark[index]}/>
            ))}



        </group>
    )
}



function Extendblock(props) {

    const [extendH,setextendH]=useState(0)


    function extendact(){
        if(extendH==0){
            var hi=window.innerWidth
            var toh=hi*145/750+10
            setextendH(toh)
        }else{
            setextendH(0)
        }

    }

    useEffect(() => {




    }, []);
    return(
        <div>
            <div className={'extendtitle'} style={{overflow:'hidden'}} onClick={()=>extendact()}>
                <img src={props.srctitle}  style={{width:'100vw',marginTop:'-1vh'}} />
            </div>
        <div className={'extendblock'}>
            <div className={'extendblockitem'} style={{height:extendH+'px',transition: 'all .3s',marginTop:'0px'}}>
                <img src={props.srcexp}  style={{width:'100vw'}} />
            </div>
        </div>
        </div>
    )
}


/**
 * Three js scene
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Scene =(props)=>{
    const isMobile=false
    const [acted, setacted] = useState(0)
    const [secViewy,setsecViewy]=useState(100)
    const [counter, setCounter] = useState(1000);
    const [tracl,settrack]= useState(0)
    const [btnalpha,setbtnalpha]= useState(0)
    const [hovered, hover] = useState(false)
    const [btncolor, setbtncolor] = useState('000')
    const [touchmove,settouchMo]=useState(false)
    const [swipery,setswipery]=useState(0)
    const [swiperindex,setswiperindex]=useState(0)
    const [swiperindexy,setswiperindexy]=useState(0)
    const [swiperbg,setswiperbg]=useState('transparent')

    const [arrowalpha,setarrowalpha]=useState(0)



    var loading=0
    var touchstartY=0
    var touchendY=0
    var scrollh=0

    var wh=window.innerHeight
    var ww=window.innerWidth
    var p4n1h=ww*399/750
    var p4n2h=ww*502/750
    var p4n3h=ww*360/750


    const p4bh=wh-p4n1h-p4n2h-p4n3h-10

    const [endph,setendph]=useState(p4bh)


    function setcolumn(){
        ontap=false

        if(column==2){
            column=1

        }else{
            column=2

            dir='up'
            showbtn=false
            setsecViewy(0)
        }


    }

    function invatescroll(e){

       // console.log(e.currentTarget.scrollTop)
        scrollh=e.currentTarget.scrollTop

    }




    function act(){
       if(!canloop)return

        if(dir=='down'){
            actcount +=1
        }else{
            actcount -=1
        }
        if(actcount>=totalconst){
            actcount=0
        }else if(actcount<0){
            actcount=totalconst-1
        }
        setacted(actcount)
        //console.log(acted)
    }

    function tous(e){

        ontap=true
        touchstartY=e.touches[0].pageY



    }
    function toumov(){
        toum=true
    }

    function toue(e){
        toum=false
         if(!ontap)return
        touchendY=e.changedTouches[0].pageY

        if(column==2){

            if(touchendY-touchstartY>0){
               // console.log('down')
                if(scrollh<=10){
                    column=1
                    setsecViewy(100)
                    setswipery(0)

                }
            }
            return
        }

        const timer = setTimeout(() => {
           if(!toum)ontap=false

        }, 2000);
        return () => clearTimeout(timer);
    }


    function swipertou(e){

        console.log(e.activeIndex)

    }


    function swipermov(e){



    }


    function swiperend(e){

      //  console.log(e)
        if(e.activeIndex==2) {
            if (e.touches.currentY-e.touches.startY < 0) {
              setswipery(-100)
            setsecViewy(0)
            column=2
               }
        }
    }
    function swiperchange(e){
        console.log(e.activeIndex)
        if(e.activeIndex==2){


            setswiperbg('#fff')
        }else{
            setswiperbg('transparent')
        }
        if(e.activeIndex==1){
            intro=false
            setCounter(100)
        }else{
            setCounter(1000)
            intro=true
        }

    }




    useLayoutEffect(()=>{

        if(showbtn){
            setbtnalpha(1)

        }else{
            setbtnalpha(0)
        }

        if(hovered){
            setbtncolor('fff')
        }else{
            setbtncolor('000')
        }






        if(intstart>=5 && !introarrow){
            introarrow=true

            setarrowalpha(1)
            console.log('show arrow')
        }

        const interval = setInterval(() => {

            if(column==1 ) {
                act()

            }
        }, counter);

        return () => clearInterval(interval);


    })

    useEffect(() => {





    }, []);





    return<div style={{width:'100%',height:'100vh',position:'fixed'}}   >

        <Canvas   camera={{ zoom: 80 }} gl={{ alpha: false, antialias: false, stencil: false, depth: false }} dpr={[1, 1.5]}>
            <Suspense fallback={null}>
            <color attach="background" args={['#fbfbfb']} />

                <Items act={acted} />



          </Suspense>
        </Canvas>







        <div style={{position:'fixed',top:swipery+'vh',height:'100vh',width:'100vw',overflow:'hidden',transition:'all .3s',backgroundColor:swiperbg}}>
        <Swiper
            className={'swipercontainer'}
            style={{top:swiperindexy+'vh'}}
            spaceBetween={0}
            slidesPerView={1}
            freeMode={true}
            initialSlide={swiperindex}
            scrollbar={{ draggable: true }}
            slideToClickedSlide={true}
            direction={"vertical"}
            effect={"coverflow"}
            onSlideChange={(e) =>swiperchange(e)}
            onTouchStart={(e)=>swipertou(e)}
            onTouchMove={(e)=>swipermov(e)}
            onTouchEnd={(e)=>swiperend(e)}
            onSwiper={(swiper) => {}}
        >
            <SwiperSlide  className={'swiper'}  >
                <div style={{width:'100vw',top:'90vh',position:'absolute'}}>
                    <img src={"asset/h5arrow.png"}    style={{width:'100vw',opacity:arrowalpha,transition:'all .4s'}} />
                </div>
            </SwiperSlide>
            <SwiperSlide  className={'swiper'}>
                <div style={{backgroundColor:'#fff',height:'25%',flex: 2}}>
                    <img src={"asset/h51.jpg"}    style={{width:'100vw'}} />
                </div>
                <div  style={{width:'100vw',flex: 2,marginTop:'-1px'}}>
                    <img src={"asset/h52.png"}  style={{width:'100vw',float:'top',}} />
                    <div style={{width:'100vw',height:'10vh',backgroundColor:'#fefefe',float:'top',marginTop:'-4vh'}}>
                    </div>
                </div>
                <div style={{backgroundColor:'#fff',flex: 2.6,marginTop:'-1px',display:'flex',alignItems:'flex-end'}}>

                    <img src={"asset/h53.jpg"}  style={{width:'100vw'}} />

                </div>
                <div style={{backgroundColor:'#fff',flex: .2,marginTop:'-1px',display:'flex',alignItems:'flex-end'}}>

                </div>

            </SwiperSlide>
            <SwiperSlide className={'swiper'}>
                <div style={{backgroundColor:'#000',height:'25%',flex: 8,margin:'0',padding:0,}}>
                    <img src={"asset/h54.jpg"}    style={{width:'100vw'}} />
                </div>
                <div style={{backgroundColor:'#fff',flex: 2,margin:'0',marginTop:'-1px',padding:0,display:'flex',alignItems:'flex-end'}}>

                    <img src={"asset/h55.jpg"}  style={{width:'100vw'}} />
                </div>

            </SwiperSlide>


        </Swiper>
        </div>

        <div className={'scrollview'} onScroll={(e)=>invatescroll(e)} onTouchStart={(e)=>tous(e)} onTouchEnd={(e)=>toue(e)} style={{backgroundColor:'#fff',position: 'absolute',top:secViewy+'vh',left:'0vw', }}>

            <div  className={'scrollviewinner'}>
                <img src={"asset/h56.jpg"}  style={{width:'100vw'}} />
                {/*<h1 style={{position: 'fixed',top:0}}>{tracl}</h1>*/}


                <Extendblock srctitle={'asset/h510.jpg'} srcexp={'asset/h511.jpg'}/>
                <Extendblock srctitle={'asset/h516.jpg'} srcexp={'asset/h517.jpg'}/>
                <Extendblock srctitle={'asset/h512.jpg'} srcexp={'asset/h513.jpg'}/>
                <Extendblock srctitle={'asset/h514.jpg'} srcexp={'asset/h515.jpg'}/>


                <div style={{height:'5vh',width:'100vw'}}>
                </div>
                <img src={"asset/h57.jpg"}  style={{width:'100vw'}} />
                <img src={"asset/h58.jpg"}  style={{width:'100vw'}} />
                <img src={"asset/h59.jpg"}  style={{width:'100vw'}} />
                <div style={{height:endph+'px',width:'100vw'}}>
                </div>


            </div>

        </div>


        <Loader
            containerStyles={{backgroundColor:'#000'}}// Flex layout styles
            innerStyles={{}}// Inner container styles
            barStyles={{}} // Loading-bar styles
            dataStyles={{}} // Text styles
            dataInterpolation={(p) => `Loading ${p.toFixed(0)}%`} // Text

        />
    </div>
}




/**
 * main view
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Home(props){

    /**
     * 是否是微信浏览器
     */
    global.isWeixin = navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1




    if (typeof window.WeixinJSBridge === "undefined") {
       // alert(222)
        console.log('no wx')
        if(document.addEventListener){
            document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {

               window.WeixinJSBridge.call('hideOptionMenu');

            });
        }

    }else{

         window.WeixinJSBridge.call('hideOptionMenu');
        window.WeixinJSBridge.call('hideAllNonBaseMenuItem');
    }


    /*
      var purl='https://campaign.irabit.net/wx/get_js_token.php?url=https://campaign.irabit.net'
      // var purl='asset/wx.json'

      if(global.isWeixin=== true) {


          Axios.get(purl).then((response) => {

            //  alert('wx')
              var resdata = response.data
              // console.log(resdata.data.appId)
              var appid = resdata.data.appId
              var timestamp = resdata.data.timestamp
              var nonceStr = resdata.data.nonceStr
              var signature = resdata.data.signature


              window.wx.config({
                  debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                  appId: appid, // 必填，企业号的唯一标识，此处填写企业号corpid
                  timestamp: timestamp, // 必填，生成签名的时间戳（10位）
                  nonceStr: nonceStr, // 必填，生成签名的随机串,注意大小写
                  signature: signature,// 必填，签名，见附录1（通过https://mp.weixin.qq.com/debug/cgi-bin/sandbox?t=jsapisign 验证）
                  jsApiList: [
                      "onMenuShareTimeline", //分享朋友圈接口
                      "onMenuShareAppMessage" //分享给朋友接口
                  ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              });

              window.wx.error(function (res) {
                  //alert(res);
                  console.log(res)
              });

              window.wx.ready(function (res) {
                  console.log('wx ready')
                  window.wx.onMenuShareAppMessage({
                      title: 'xxxx',
                      desc: 'xxx',
                      link: 'link',
                      imgUrl: 'Imgurl',
                      trigger: function (res) {
                      },
                      success: function (res) {
                      },
                      cancel: function (res) {
                      },
                      fail: function (res) {
                      }
                  });
                  window.wx.onMenuShareTimeline({
                      title: 'xx',
                      link: '',
                      imgUrl: '',
                      trigger: function (res) {
                      },
                      success: function (res) {
                      },
                      cancel: function (res) {
                      },
                      fail: function (res) {
                      }
                  });
              });


          }).catch(error => {
              //我们可以将状态更新为错误以在屏幕上显示有意义的消息
              console.log(error)
          });
          ;

      }

*/






    return(
    <div >

        <Scene/>
    </div>
    )





}


export default Home