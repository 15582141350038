import {HashRouter as Router,Routes,Route,Redirect} from 'react-router-dom';
import React, { Suspense, useState, useCallback, useEffect, useRef, useMemo } from 'react'
import './App.css';
import Home from './Home/Home'
import {isMobile} from 'react-device-detect';

function Callhome(){
    return(
        <Router>

            <div className='mainContentBox' >
                <Routes>

                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />


                </Routes>
            </div>



        </Router>
    )
}


function App() {



  return (
        <>
            { (isMobile==true)?(<Callhome />):(<div></div>) }

        </>
  );
}

export default App;
